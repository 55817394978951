* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

html {
  height: 100%;
  scroll-behavior: auto;
}
body {
  min-height: 100%;
  position: relative;
  font-family: 'Helvetica Neue';
  font-weight: 400;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-tap-highlight-color:rgba(0,0,0,0);

  &.overflow{
    overflow:hidden;
  }

}
.mx-1660{
  max-width: 1960px;
  padding:0 150px;
  margin: auto;
  @media (max-width:1440px){
    padding: 0 20px;
    max-width:1180px;
  }
  @media (max-width:1300px){
    max-width:100%;
    padding:0 50px;
    @include phonel{
      padding:0 20px;
    }
  }
  &.for_space_dropdown{

  }
}
.mx-1140{
  max-width: 1180px;
  padding:0 20px;
  margin: auto;
  @media (max-width:1300px){
    max-width:100%;
    padding:0 50px;
    @include phonel{
      padding:0 20px;
    }
  }
}
.container-fluid{
  max-width:100%;
  padding:0 40px;
  @include tabm{
    padding:0 20px;
  }
}
ul,
li,
a,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
figure{
  margin-bottom: 0px;
}
ul,
ol {
  padding-left: 0;
}
.pr-0{
  padding-right: 0;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}
button:focus {
  outline: none;
}


.main_wrapper{
  min-height:300px
}



/* width */
//::-webkit-scrollbar {
//  width: 0px;
//}
//
///* Track */
//::-webkit-scrollbar-track {
//  background: transparent;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//  background: transparent;
//}
//Bootstrap 5 specific changes
.row{
  --bs-gutter-x: 30px;
}
.graybg{
  background:#f9fafb;
}
.space60{
  height:60px;
  @include phonel{
    height:45px;
  }
}
.space1{
  height:1px;
  width: 100%;
  background: #DADADA;
}

.overflow{
  overflow: hidden;
}


.error{
  .mobile_header {
    .mobile_back_btn, .menu-btn, .hamburger-menu {
      display: none !important;
    }
    .logo_wrapper{
      width:100% !important;
    }
  }
}


.external_link_wrapper{
  height:100%;
  width:100%;
  max-height:100%;
  .p-dialog-header{
    display:none;
  }
  .p-dialog-content{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .modal-content{
      align-items: center;
      .desc{
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color:#96999F;
        margin:auto;
        margin-bottom:20px;
        width:470px;
        @include phonel{
          font-size: 17px;
          font-weight: 400;
          line-height: 26px;
          text-align: center;
          color: #96999F;
          margin: auto;
          margin-bottom: 20px;
          width: 280px;
        }
      }
    }
    .link-primary-btn{
      font-size: 18px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: left;
      color:#DC5A4B;
      @include trans2s;
      svg path{
        @include trans2s;
      }
      &:hover{
        color:#E37B6F;
        svg path {
          stroke:#E37B6F;
        }
      }
      .icon{
        display:inline-flex;
        align-items:center;
        justify-content: center;
        width:24px;
        height: 24px;
        margin-right:4px;
      }
    }

  }
}
