
/** Helpers includes all variabls and config files **/
@import
	'helpers/_mixins.scss';

/** Base includes all basic requirements for every page **/
@import
	'base/_colors.scss';

/** Components includes buttons, carousel, dropdown etc.. **/
@import
	'components/_buttons.scss',
	'components/_headings.scss',
	'components/_cards.scss';

/** Layout folder styles for major sections of the layout like a header, footer and styles for a grid system **/
@import
	'layout/_header.scss',
	'layout/_footer.scss',
	'layout/_forms.scss',
	'layout/_common.scss';

@import
 	'pages/_home.scss';



/** custom writter css **/
@import
	'main.scss';
	/** all common styles written in main.scss **/
