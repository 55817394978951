
.heading_1{
  color:var(--primary);
  font-family: var(--font_Souvenir_light);
  @include remy(52);
  font-style: normal;
  font-weight: 300;
  line-height: 62px;
}
.heading_2{
  @include remy(38);
  font-weight: 300;
  line-height: 57px;
  text-align: center;
  color:var(--primary);
  font-family: var(--font_Souvenir_light);
  @include phonel{
    @include remy(28);
    font-style: normal;
    font-weight: 300;
    line-height: 46px;
    letter-spacing: 0px;
  }
}

.heading_3{
  @include remy(34);
  font-weight: 300;
  line-height: 50px;
  letter-spacing: 0;
  color:var(--primary);
  font-family: var(--font_Souvenir_light);
}

.heading_4{
  @include remy(28);
  line-height: 46px;
  letter-spacing: 0;
  text-align: center;
  color:var(--primary);
}

.heading_5{
  @include remy(22);
  line-height: 32px;
  letter-spacing: 0;
  color:var(--primary);
  font-weight: 300;
}

.subtitle1{
  @include remy(18);
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0;
  font-family: 'Helvetica Neue md';
}

.subtitle2{
  @include remy(15);
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0;
  font-weight:400;
}

.subtitle3{
  @include remy(14);
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0;
  font-family: 'Helvetica Neue md';
}


.para1{
  color: var(--primary);
  @include remy(16);
  font-style: normal;
  line-height: 21px;
}
.para2{
  color: var(--primary);
  text-align: center;
  @include remy(16);
  font-style: normal;
  font-family: 'Helvetica Neue md';
  line-height: 32px;
}
.para3{
  color: var(--primary);
  text-align: center;
  @include remy(14);
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.para4{
  color: var(--primary);
  text-align: center;
  @include remy(16);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}


.text_link1{
  @include remy(15);
  font-style: normal;
  font-family: 'Helvetica Neue md';
  line-height: 21px;
  letter-spacing:0;
  background: none;
  border: none;
  text-decoration: underline;
  @include trans2s;
  &:hover{
    @include phonel-m{
        opacity: 0.6;
      }
  }
}

.text_link2{
  @include remy(14);
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.2px;
}

.fav_tag, .local_tag{
  @include remy(11.2);
  font-style: normal;
  font-family: 'Helvetica Neue md';
  line-height: 18px;
  letter-spacing: 0.05em;
}
