@use "sass:math";
/** Box Shadow Mixins **/

/** Box Shadow Mixins **/
@mixin box-shadow($top, $left, $blur, $color, $inset: "") {
  -webkit-box-shadow: $top $left $blur $color #{$inset};
  -moz-box-shadow: $top $left $blur $color #{$inset};
  box-shadow: $top $left $blur $color #{$inset};
}

/** Transition **/
/*@include transition(background-color 1s .5s, color 2s);*/
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin trans2s {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}
@mixin trans4s {
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}


/*Font size mixin*/
@mixin remy($pxsize) {
  font-size: math.div($pxsize, 16) + rem;
}

@mixin transition2s{
  transition: 0.2s all ease-in-out;
}


/* responeive mixin & varibale */


$phones: 480px;
$phonem: 599px;
$phonel: 767px;
$tabm: 991px;
$tab-m: 992px;
$tabland:1024px;
$tabv: 1100px;
$tabv-min: 1100px;
$tabl: 1200px;
$tabl-m: 1200px;
$desk: 1400px;
$phonel-m: 768px;
$phone-sm: 365px;
$desk-sm:1300;

@mixin dsk-tab {
  @media (min-width: #{$tabm}),  (max-width: #{$tabl}) {
    @content;
  }
}


@mixin tab-phn {
  @media (min-width: #{$tabl}),  (max-width: #{$phonel}) {
    @content;
  }
}

@mixin phonem {
  @media (max-width: #{$phonem}) {
    @content;
  }
}

@mixin desk-sm {
  @media (max-width: #{$desk-sm}) {
    @content;
  }
}

@mixin tabland {
  @media (max-width: #{$tabland}) {
    @content;
  }
}
@mixin tabl {
  @media (max-width: #{$tabl}) {
    @content;
  }
}
@mixin tabl-m {
  @media (min-width: #{$tabl-m}) {
    @content;
  }
}
@mixin tabm {
  @media (max-width: #{$tabm}) {
    @content;
  }
}

@mixin tabv {
  @media (max-width: #{$tabv}) {
    @content;
  }
}

@mixin tabv-min {
  @media (min-width: #{$tabv-min}) {
    @content;
  }
}

@mixin tab-m {
  @media (min-width: #{$tab-m}) {
    @content;
  }
}




@mixin phonel {
  @media (max-width: #{$phonel}) {
    @content;
  }
}

@mixin phones {
  @media (max-width: #{$phones}) {
    @content;
  }
}

@mixin phone-sm {
  @media (max-width: #{$phone-sm}) {
    @content;
  }
}

@mixin desk {
  @media (max-width: #{$desk}) {
    @content;
  }
}

@mixin phonem-m {
  @media (min-width: #{$phonel}) {
    @content;
  }
}

@mixin tabl-m {
  @media (min-width: #{$tabl}) {
    @content;
  }
}
@mixin tabl-v {
  @media (min-width: #{$tabm}) {
    @content;
  }
}

@mixin phonel-m {
  @media (min-width: #{$phonel-m}) {
    @content;
  }
}
//==============
@mixin dib100 {
  display: inline-block;
  width: 100%;
}
@mixin trans2 {
  transition:0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition:0.2s ease-in-out;
}
@mixin dib {
  display: inline-block;
  //display: inline;
  zoom: 1;
}



