.custom_input_transparent {
  background-color: transparent;
  border: none;
  position: relative;
  @include remy(30);
  color: $fc_1;
  padding: 0;

  &.dark_placeholder {
    &::placeholder {
      color: $fc_1;
    }
  }

  &.light_placeholder {
    &::placeholder {
      color: #c3beb4;
    }
  }

  &.transparent_placeholder {
    &::placeholder {
      color: transparent;
    }
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #D7D0CA inset !important;

    &:hover {
      -webkit-box-shadow: 0 0 0 30px #D7D0CA inset !important;
    }

    &:focus {
      -webkit-box-shadow: 0 0 0 30px #D7D0CA inset !important;
    }

    &:active {
      -webkit-box-shadow: 0 0 0 30px #D7D0CA inset !important;
    }
  }

  //~ .btn_submit_1 {
  //  visibility: hidden;
  //}

  //&::placeholder {
  //  color: transparent;
  //  @include remy(30);
  //  line-height: 1.1;
  //}

  //&:hover {
  //  ~ .ip_footer {
  //    &.ip_email {
  //      display: block;
  //    }
  //
  //    &.ip_stay {
  //      display: none;
  //    }
  //  }
  //
  //  //~ .btn_submit_1 {
  //  //  visibility: visible;
  //  //}
  //}
  //
  &:focus {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  //
  //&:focus-visible {
  //  color: $fc_1;
  //
  //  ~ .ip_footer {
  //    &.ip_email {
  //      display: none;
  //    }
  //
  //    &.ip_stay {
  //      display: none;
  //    }
  //  }
  //
  //  &::placeholder {
  //    display: block;
  //  }
  //
  //  //~ .btn_submit_1 {
  //  //  visibility: visible;
  //  //}
  //}
  //
  //&:focus-within {
  //  background-color: transparent;
  //  border: none;
  //}
}


//.ip_footer {
//  @include remy(30);
//  line-height: 1.1;
//  color: $fc_1;
//  position: absolute;
//  top: 50%;
//  transform: translateY(-50%);
//  left: 0px;
//
//  &.ip_email {
//    display: none;
//    color: #C3BEB4;
//  }
//}

//.ip_stay {
//  &:hover {
//    display: none;
//
//    ~ .ip_footer {
//      &.ip_email {
//        display: block;
//      }
//    }
//
//    //~ .btn_submit_1 {
//    //  visibility: visible;
//    //}
//  }
//}

.btn_submit_1 {
  &:hover {
    visibility: visible;

    //~ .ip_footer {
    //  display: none;
    //
    //  &.ip_email {
    //    display: none;
    //  }
    //
    //  &.ip_stay {
    //    display: none;
    //  }
    //}

    //~ .custom_input_transparent {
    //  &::placeholder {
    //    color: $fc_1;
    //    @include remy(30);
    //    line-height: 1.1;
    //  }
    //}
  }

  &:focus-visible {
    visibility: visible;

    //~ .ip_footer {
    //  &.ip_email {
    //    display: none;
    //  }
    //
    //  &.ip_stay {
    //    display: none;
    //  }
    //}

    //~ .custom_input_transparent {
    //  &::placeholder {
    //    color: $fc_1;
    //    @include remy(30);
    //    line-height: 1.1;
    //  }
    //}
  }
}

.radio_selection_group {
  cursor: pointer;
  //display: flex;
  //align-items: flex-start;
  //flex-direction: column;
  //justify-content: center;
  //margin-bottom: 17px;
  @include dib100;

  .mat-radio-button {
    width: 100%;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    .mat-radio-label-content {
      color: #444444;
      //@include f_regular;
      padding-left: 13px;
      @include remy(14);
    }

    .mat-radio-container {
      width: 22px;
      height: 22px;

      .mat-radio-outer-circle {
        width: 22px;
        height: 22px;
        border-color: #EBEDF2;
      }

      .mat-radio-inner-circle {
        width: 22px;
        height: 22px;
      }
    }

    &.mat-radio-checked {
      //.mat-radio-persistent-rippl{
      //  background-color: transparent;
      //}
      .mat-radio-container {

        .mat-radio-outer-circle {
          border-color: #DC5F50;
        }

        .mat-radio-inner-circle {
          background-color: #EC684B;
        }
      }
    }
  }


  //span {
  //  color: #444444;
  //}
  //
  //.mat-radio-button {
  //  margin-top: 8px;
  //}
  //
  //.mat-radio-outer-circle {
  //  width: 16px;
  //  height: 16px;
  //  border: 2px solid #EBEDF2;
  //  box-sizing: border-box;
  //}
  //
  //.mat-radio-inner-circle,
  //.mat-radio-container {
  //  width: 16px;
  //  height: 16px;
  //}
}

//.radio_selection_ui{
//  @include  dib100;
//
//}

.form_inner_section {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  .f_18 {
    margin-bottom: 13px;
  }
}

textarea.mat-input-element {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 18px;
  width: 100%;
  min-height: 100px;
  border: 2px solid #EBEDF2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 14px 0;
}

.selection_ui {
  cursor: pointer;
  display: flex;
  width: 100%;
  position: relative;
  @media screen and (max-width: 575px) {
    @include dib100;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &:hover {
    .content_ui {
      border-color: $fc-primary;

      .icon {
        opacity: 1;
      }
    }
  }

  .content_ui {
    background: #FFFFFF;
    border: 2px solid #EBEDF2;
    //outline: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 22px;
    @include trans2;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      left: -3px;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      top: -3px;
      border: 1px solid $fc_4;
      border-radius: 9px;
      opacity: 0;
      background: $fc_4;
      z-index: -1;
      @include trans2;
    }

    @media (min-width: 991px) and (max-width: 1100px) {
      padding: 22px 10px 22px 22px;
    }

    @include phonem {
      padding: 17px 30px;
    }

    .icon {
      margin-bottom: 0;
      max-width: 52px;
      min-width: 52px;
      opacity: 0.3;
      @include trans2;
    }

    p {
      width: calc(100% - 52px);
      padding-left: 16px;
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $fc_5;
      @media (min-width: 991px) and (max-width: 1100px) {
        @include remy(16);
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 1;

    &:checked + .content_ui {
      border: 2px solid $fc_4;
      //outline: 1px solid $fc_4;
      box-shadow: 0 6px 12px rgb(189 70 56 / 25%);

      &:after {
        opacity: 1;
      }

      .icon {
        opacity: 1;
      }
    }

    //&:checked ~ .content_ui {
    //  border: 3px solid #DC5F50;
    //  box-shadow: 0 6px 12px rgba(189, 70, 56, 0.25);
    //  img {
    //    opacity: 1 !important;
    //  }
    //}
  }


}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom_input_mat_1 {
  width: 100%;
  //height: 42px;
  //.mat-form-field-outline-start{
  //
  //}
}

//.mat-form-field-appearance-outline .mat-form-field-outline-start{
//  border-radius: 4px 0 0 4px!important;
//  border-left: 2px solid #EBEDF2!important;
//}
//.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-start{
//  border-left: 2px solid #EBEDF2!important;
//}
.custom_input_mat_1.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 14px;
  margin-bottom: 10px;
}

.mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix {
  padding: 12px 0px
}

.mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper {
  top: -1.1em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {

  transform: translateY(-1.4em) scale(.75);
  width: 133.33333%;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  color: $fc_5;
}

//.mat-radio-button:not(.mat-radio-disabled).cdk-program-focused-mat-radio-persistent-ripple{
//  opacity: 0!important;
//}
.mat-ripple-element.mat-radio-persistent-ripple {
  opacity: 0;
  display: none !important;
}

//.mat-form-field {
//  @include dib100;
//
//  &.mat-form-field-should-float {
//    .mat-form-field-wrapper {
//      .mat-form-field-flex {
//        border: 2px solid cornflowerblue;
//      }
//    }
//
//    .mat-form-field-label {
//      transform: translateY(-1.19375em) scale(0.75) !important;
//    }
//  }
//
//  &.mat-warn,
//  &.mat-form-field-invalid {
//    .mat-form-field-wrapper {
//      .mat-form-field-flex {
//        border: 2px solid red;
//      }
//    }
//  }
//
//  .mat-form-field-wrapper {
//    .mat-form-field-flex {
//      background: transparent;
//      padding: 2px 10px !important;
//      @include dib100;
//      @include remy(16);
//      line-height: 1.1;
//      color: $fc_1;
//      border-radius: 4px;
//      position: static;
//      width: 100%;
//      height: 42px;
//      border: 2px solid #EBEDF2;
//    }
//
//    .mat-form-field-label-wrapper {
//      top: -8px;
//    }
//
//    .mat-form-field-infix {
//      border: 0;
//      padding: 10px 0;
//    }
//
//    .mat-form-field-label {
//      top: 1.55em;
//    }
//
//    mat-label {
//      background: white;
//      padding: 2px;
//    }
//
//    .mat-form-field-underline {
//      display: none;
//    }
//  }
//
//  &.w-100 {
//    .mat-form-field-flex {
//      width: 100% !important;
//    }
//  }
//}
@media (min-width: 768px) and (max-width: 991px) {
  .form-group .col-sm-6:first-child {
    margin-bottom: 24px;
  }
}

@include phonem {
  .form-group .col-sm-6:first-child {
    margin-bottom: 24px;
  }
}

.textarea_wrapper {
  .fg-inner {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }
}

.form_group_1 {
  @include dib100;
  margin-bottom: 8px;

  .input,
  input {
    @include dib100;
    @include remy(16);
    line-height: 1.1;
    color: $fc_1;
    border-radius: 4px;
    padding: 12px 18px;
    position: static;
    width: 262.5px;
    height: 42px;
    border: 2px solid #EBEDF2;
    background-color: transparent;
  }
}

//.mr {
//  margin-right: 12px;
//}

//.mat-checkbox-layout {
//margin-top: 4px;

//.mat-checkbox-frame {
//  border: 2px solid #EBEDF2;
//  box-sizing: border-box;
//  border-radius: 4px;
//  height: 22px;
//  width: 22px;
//}
//
//.mat-checkbox-label {
//  margin-left: 8px;
//  vertical-align: middle;
//}
//
//.mat-checkbox-background {
//  height: 22px;
//  width: 22px;
//}
//
//.mat-checkbox-inner-container {
//  margin-top: 0;
//}
//}
.mat_checkbox_ui {
  @include dib100;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  .mat-checkbox-frame {
    border-color: #EBEDF2;
    border-radius: 4px;
  }

  .mat-checkbox-inner-container {
    width: 22px;
    height: 22px;
  }

  .mat-checkbox-label {
    display: inline-block;
    width: 100%;
    @include remy(14);
    line-height: 17px;
    color: #444444;
    padding-left: 13px;
  }

  &.mat-checkbox-checked {

    .mat-checkbox-layout {
      .mat-checkbox-background {
        background-color: #EC684B;
        border-radius: 4px;
      }
    }
  }
}

.form_group_flex {
  //@include fstart;

  //justify-content: space-between;
  width: 100%;
}

.form_group_flex_col {
  //@include fstart;
  //@include fcol;

  justify-content: space-between;
  width: 100%;
}

.text_area_ui {
  padding: 5px 5px !important;
  line-height: 1;
}

.custom_input_1 {
  @include dib100;
  @include remy(16);
  line-height: 1.1;
  color: $fc_1;
  border-radius: 4px;
  padding: 12px 18px;
  position: static;
  width: 262.5px;
  height: 42px;
  border: 2px solid #EBEDF2;
  background-color: transparent;

  &.textarea_ui {
    height: 60px;
    border-radius: 22px;
    resize: none;
  }

}

.iti {
  width: 100%;
}

.ip_group_ui {
  border: 1px solid $fc_1;
  border-radius: 100px;
  overflow: hidden;
  height: 44px;

  .input-group-text {
    background-color: transparent;
    border: none;
  }

  .input_inner {
    border: none;
    text-align: center;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.radio_listing_outer {
  @include dib100;

  .radio_listing {
    @include dib100;
    padding: 12px 16px;

    input {
      margin-right: 12px;
    }

    span {
    }
  }
}


/* Chrome, Safari, Edge, Opera */
.input_number_ui_remove::-webkit-outer-spin-button,
.input_number_ui_remove::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input_number_ui_remove[type=number] {
  -moz-appearance: textfield;
}


/* form css start here */
.custom-input-1 {
  @include dib100;
  background-color: $f_white;
  border: 2px solid $border-light;
  height: 50px;
  border-radius: 4px;
  padding: 0px 14px;
  font-style: normal;
  font-weight: 400;
  @include remy(18);
  line-height: 21px;
  color: $fc_5;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    @include remy(18);
    line-height: 21px;
    color: $fc-hint;
  }

  &:focus {
    box-shadow: none;
    border-color: #96999F;

    &::placeholder {
      color: transparent;
    }
  }

  &.ng-touched {
    &.ng-invalid {
      border: 2px solid $fc-red;

      &::placeholder {
        color: $fc-hint;
      }

      color: $fc-primary;
    }
  }

  &:disabled {
    background-color: #F5F5F5;
    border: none;

    &::placeholder {
      color: #96999F;
    }
  }

  &.custom-text-area {
    height: 109px;
    resize: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &:-internal-autofill-selected,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px white inset !important;
    background: #fff !important;
    transition: 0.01s ease-in-out;
  }
}


.joinus_form_wrapper {
  .form-group {
    margin-bottom: 24px;

    .form-label {
      font-style: normal;
      @include remy(16);
      line-height: 19px;
      color: $fc_5;
      margin-bottom: 6px;
      display: block;
      font-family: 'Helvetica Neue md';

      strong {
        font-weight: 500;
      }
    }
  }
}

//radio button starts
.form-group.radio_btn_wrapper {
  margin-bottom: 40px;

  .radio-label {
    font-style: normal;
    font-weight: 500;
    @include remy(16);
    line-height: 19px;
    margin-bottom: 20px;
    color: $fc-primary;
    font-family: 'Helvetica Neue md';
  }
}

.radio_button_group {
  display: block;
  position: relative;
  margin-bottom: 16px;
  @include remy(16);
  line-height: 22px;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: inline-block;
    padding-left: 32px;
    align-items: flex-start;
    position: relative;

    .content_ui {
      @include remy(16);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $fc_5;
      word-break: break-word;
    }

    &:hover {
      //.checkmark{
      //  border-color: $border-dark;
      //}
      svg .outer_ui {
        stroke: $border-dark;
      }
    }

    &:focus {
      .checkmark:before {
        animation: ripple 0.3s alternate;
        transform: scale(1);
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;

    &:checked ~ .checkmark:after {
      opacity: 1;
      visibility: visible;
      transform: scale(0.6);
    }

    &:focus ~ .checkmark_icon:before,
    &:checked ~ .checkmark_icon:before {
      animation: ripple 0.3s alternate;
      transform: scale(1);
      transition-duration: 150ms;
    }

    &:checked ~ .checkmark_icon {
      //border: 2px solid #DC5A4B;
      svg {
        .outer_ui {
          stroke: $orange;
        }

        .inner_ui {
          transform: scale(1);
          -webkit-transform: scale(1);
          opacity: 1;
          stroke: $orange;
          fill: $orange;
        }
      }
    }
  }


  .checkmark_icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    border: 2px solid #E0E1E5;
    border-radius: 100%;
    @include trans2;
    background-color: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:before {
      position: absolute;
      content: "";
      left: -12px;
      top: -12px;
      width: 40px;
      height: 40px;
      background: rgba($orange, 0.2);
      border-radius: 100%;
      opacity: 0;
      transform: scale(0.1);
    }

    svg {
      min-width: 20px;

      .outer_ui {
        stroke: #E0E1E5;
        @include trans2;

      }

      .inner_ui {
        transform: scale(0.1);
        -webkit-transform: scale(0.1);
        transform-origin: center;
        //top: 50%;
        //left: 50%;
        opacity: 0;
        @include trans2;
        stroke: $orange;
        fill: $orange;
      }
    }
  }
}

//radio button ends

// check box starts
.form-group.checkbox_wrapper {
  margin-bottom: 40px;

  .checbox-label {
    font-style: normal;
    font-weight: 500;
    @include remy(16);
    line-height: 19px;
    color: $fc-primary;
    margin-bottom: 2px;
    font-family: 'Helvetica Neue md';
  }

  .info_text {
    font-style: normal;
    font-weight: 400;
    @include remy(14);
    line-height: 17px;
    color: $fc-secondary;
    margin-bottom: 20px;
  }

  .checkbox_button_group {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.checkbox_button_group {
  display: block;
  position: relative;
  margin-bottom: 16px;
  @include remy(16);
  line-height: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: inline-block;
    align-items: flex-start;
    padding-left: 32px;
    position: relative;

    .content_ui {
      @include remy(16);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $fc_5;
      word-break: break-word;
    }

    &:hover {
      .checkmark {
        border-color: $border-dark;
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    left: 0;

    &:checked ~ .checkmark:after {
      opacity: 1;
      visibility: visible;
      transform: scale(1);

    }

    &:checked ~ .checkmark {
      background-color: $orange;
      border-color: $orange;
    }

  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #E0E1E5;
    border-radius: 4px;
    transition: 0.3s ease-in-out;
    background-color: #ffffff;

    &:after {
      content: "";
      position: absolute;
      color: #ffffff;
      @include remy(14);
      background: url("/assets/images/icon_check.svg");
      background-repeat: no-repeat;
      width: 17px;
      height: 16px;
      background-position: center;
      @include trans2;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.6);
    }

  }

  .content_ui {
    a {
      text-decoration: underline;
      color: #7babb7;
    }
  }

}

//checkbox ends


.phone-field-wrapper {
  background: none;
  border: none;
  padding: 0;

  &.ng-touched {
    &.ng-invalid {
      border: none;

      .p-inputtext {
        border: 2px solid $fc-red;

        &::placeholder {
          color: $fc-hint;
        }

        color: $fc-primary;

        &:focus {
          border-color: $fc-red !important;
        }
      }

      &:hover {
        .p-inputtext {
          border-color: $fc-red;
        }
      }
    }
  }

  .p-inputtext {
    @include dib100;
    background-color: $fc-white;
    border: 2px solid $border-light;
    height: 50px;
    border-radius: 4px;
    padding: 0px 14px;
    font-style: normal;
    font-weight: 400;
    @include remy(18);
    line-height: 21px;
    color: $fc-primary;

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $fc-hint;
    }

    &:focus {
      box-shadow: none;
      border-color: #96999F !important;

      &::placeholder {
        color: transparent;
      }
    }

    &:disabled {
      background-color: #F5F5F5;
      border: none;

      &::placeholder {
        color: #96999F;
      }
    }

    &.custom-text-area {
      height: 90px;
      resize: none;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

//Single Select Dropdown

.pi-chevron-down {
  content: "";
  background: url("../../../assets/images/up_angel_icon.svg") no-repeat;
  width: 17px;
  height: 9px;
  font-size: 0;
  right: 16px !important;
  margin-top: 10px !important;
  transform: translateY(-50%);

  &:before {
    font-size: 0;
  }
}

.singleSelectDropdown {
  border: 2px solid $border-light;
  border-radius: 4px;
  min-width: 188px;
  width: 100%;
  height: 50px;
  display: inline-flex;
  align-items: center;
  @include trans2;
  @include tabm {
    min-width: 100%;
  }

  &:not(.p-disabled) {
    &:hover {
      border: 2px solid $border-light;
    }

    &.p-dropdown-open {
      &.p-focus {
        box-shadow: none;
        border: 2px solid #96999F;

        .p-dropdown-label {
          &.p-placeholder {
            opacity: 0;
          }
        }

        .p-dropdown-trigger-icon {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      .p-dropdown-trigger-icon {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &.p-focus {
      box-shadow: none;
      border: 2px solid $border-light;
    }
  }

  .p-dropdown-label {
    font-style: normal;
    font-weight: 400;
    @include remy(18);
    line-height: 21px;
    color: $fc-primary;

    &.p-placeholder {
      font-style: normal;
      font-weight: 400;
      @include remy(18);
      line-height: 21px;
      color: $fc-hint;
    }
  }

  .p-dropdown-trigger-icon {
    @include remy(12);
  }

  .p-dropdown-panel {
    height: auto;
    max-height: 196px;
    overflow-y: auto;
    border: 2px solid $border-light;
    filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.2));
    border-radius: 4px;

    .p-dropdown-items {
      padding: 0px;

      .p-dropdown-item {
        font-style: normal;
        font-weight: 400;
        @include remy(16);
        line-height: 22px;
        color: $fc-primary;
        padding: 10px 15px;

        &.p-highlight {
          background: rgba(85, 146, 160, 0.1);
        }

        &:not(.p-highlight) {
          &:not(.p-disabled) {
            &:hover {
              background-color: rgba(85, 146, 160, 0.1);
            }
          }
        }
      }
    }
  }
}

.singleselect-wrapper {
  .ng-touched {
    &.ng-invalid {
      .singleSelectDropdown {
        border-color: $fc-red;
      }
    }
  }

  .p-dropdown-clear-icon {
    display: none;
  }
}

.calendar_wrapper.disabled {
  .p-dropdown .p-dropdown-trigger {
    opacity: 0.4;
  }
}


/*tooltip css*/
.cstm_tooltip {
  .p-tooltip {
    min-width: 285px !important;
    top: -120px !important;
    right: 0 !important;
    left: initial !important;
  }

  .p-tooltip-text {
    background: #F5F5F5;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 16px 20px;
    border-radius: 0;
    color: $fc-primary;
  }

  .p-tooltip-arrow {
    border-top-color: #F5F5F5 !important;
    margin-left: -1rem !important;
    border-width: 1em 1em 0 !important;
    bottom: -5px;
    right: 8px !important;
    left: initial !important;
  }

  h6 {
    display: inline-flex;
    padding: 10px;
  }

  .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #F5F5F5 !important;
  }
}


.flash-loader {
  background: rgba(#fff, 0.95);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
    color: #444;
    font-family: 'Helvetica Neue';
    text-align: center;
    padding: 0 10px;
  }

  .loader-inner {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}


/* loader 2*/

.dot-flashing {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 20px;
  background-color: $teal-shade-2;
  color: $teal-shade-2;
  -webkit-transform: scale(1.25, 1.25);
  -ms-transform: scale(1.25, 1.25);
  transform: scale(1.25, 1.25);
  -webkit-animation: dotStretching 1s infinite ease-in;
  animation: dotStretching 1s infinite ease-in;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  &:before, &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }


  &:before {
    width: 14px;
    height: 14px;
    border-radius: 20px;
    background-color: $teal-shade-2;
    color: $teal-shade-2;
    -webkit-animation: loader2Before 1s infinite ease-in;
    animation: loader2Before 1s infinite ease-in;
  }

  &:after {
    width: 14px;
    height: 14px;
    border-radius: 20px;
    background-color: $teal-shade-2;
    color: $teal-shade-2;
    -webkit-animation: loader2After 1s infinite ease-in;
    animation: loader2After 1s infinite ease-in;
  }

}


@-webkit-keyframes loader2 {
  0% {
    -webkit-transform: scale(1.25, 1.25);
    transform: scale(1.25, 1.25);
  }
  50%,
  60% {
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  100% {
    -webkit-transform: scale(1.25, 1.25);
    transform: scale(1.25, 1.25);
  }
}

@keyframes loader2 {
  0% {
    -webkit-transform: scale(1.25, 1.25);
    transform: scale(1.25, 1.25);
  }
  50%,
  60% {
    -webkit-transform: scale(0.8, 0.8);
    transform: scale(0.8, 0.8);
  }
  100% {
    -webkit-transform: scale(1.25, 1.25);
    transform: scale(1.25, 1.25);
  }
}

@-webkit-keyframes loader2Before {
  0% {
    -webkit-transform: translate(0) scale(0.7, 0.7);
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    -webkit-transform: translate(-25px) scale(1, 1);
    transform: translate(-25px) scale(1, 1);
  }
  100% {
    -webkit-transform: translate(0) scale(0.7, 0.7);
    transform: translate(0) scale(0.7, 0.7);
  }
}

@keyframes loader2Before {
  0% {
    -webkit-transform: translate(0) scale(0.7, 0.7);
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    -webkit-transform: translate(-25px) scale(1, 1);
    transform: translate(-25px) scale(1, 1);
  }
  100% {
    -webkit-transform: translate(0) scale(0.7, 0.7);
    transform: translate(0) scale(0.7, 0.7);
  }
}

@-webkit-keyframes loader2After {
  0% {
    -webkit-transform: translate(0) scale(0.7, 0.7);
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    -webkit-transform: translate(25px) scale(1, 1);
    transform: translate(25px) scale(1, 1);
  }
  100% {
    -webkit-transform: translate(0) scale(0.7, 0.7);
    transform: translate(0) scale(0.7, 0.7);
  }
}

@keyframes loader2After {
  0% {
    -webkit-transform: translate(0) scale(0.7, 0.7);
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    -webkit-transform: translate(25px) scale(1, 1);
    transform: translate(25px) scale(1, 1);
  }
  100% {
    -webkit-transform: translate(0) scale(0.7, 0.7);
    transform: translate(0) scale(0.7, 0.7);
  }
}

/* Loader 2*/


.joinus_form_wrapper .form-group {
  &.long_error {
    margin-top: -15px;

    a {
      color: #006878;
    }
  }
}
