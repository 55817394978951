
.product-carousel_wrapper{
  .slick-arrow{
    position: absolute;
    right:0;
    top:-62px;
    width:40px;
    height: 40px;
    border-radius:100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background:#fff;
    @include trans4s;
    border: 1px solid var(--primary);
    svg path{
      @include trans2s;
    }
    &:hover{
      background:var(--primary);
      svg path{
        stroke:#fff;
      }
    }
    &.slick-disabled{
      opacity: 0.3;
      cursor:auto;
      &:hover{
        background:transparent;
        border: 1px solid var(--primary);
        opacity: 0.3;
        svg path{
          stroke:var(--primary);
        }
      }
    }
    &.slick-prev{
      right:64px;
    }
    &.slick-next{
      right:0px;
    }
  }
  .product_skeleton {
    @media (min-width:992px){
      height:262px;
      gap: 30px;
      .p-skeleton {
        height:262px !important;
        width: 262px !important;
      }
    }
    @media (min-width: 768px) and  (max-width: 991px){
      height:262px;
      gap: 20px;
      .p-skeleton {
        height: 262px !important;
        width: 242px !important;
      }
    }
    @media (max-width:767px){
      min-height:250px;
      padding-bottom:14px;
      gap: 10px;
      .p-skeleton {
        height: 250px !important;
        width:250px !important;
      }
    }
    @media (min-width: 410px) and  (max-width: 430px){
      min-height:155px;
      padding-bottom:14px;
      gap: 10px;
      .p-skeleton {
        height: 155px !important;
        width: 155px !important;
      }
    }
    @media (min-width: 320px) and  (max-width: 400px){
      min-height:138px;
      padding-bottom:14px;
      gap: 10px;
      .p-skeleton {
        height: 138px !important;
        width: 138px !important;
      }
    }
  }
  .space60{
    //height:43px;
  }
  .spacer{
    overflow:hidden;
    @media (min-width:992px){
      height:262px;
      gap: 30px;
      .p-skeleton {
        height:262px !important;
        width: 262px !important;
      }
    }
    @media (min-width: 768px) and  (max-width: 991px){
      height:262px;
      gap: 20px;
      .p-skeleton {
        height: 262px !important;
        width: 242px !important;
      }
    }
    @media (max-width:767px){
      min-height:auto;
      padding-bottom:14px;
      gap: 10px;
      .p-skeleton {
        height: 250px !important;
        width:250px !important;
      }
    }
    @media (min-width: 410px) and  (max-width: 430px){
      min-height:155px;
      padding-bottom:14px;
      gap: 10px;
      .p-skeleton {
        height: 155px !important;
        width: 155px !important;
      }
    }
    @media (min-width: 320px) and  (max-width: 400px){
      min-height:138px;
      padding-bottom:14px;
      gap: 10px;
      .p-skeleton {
        height: 138px !important;
        width: 138px !important;
      }
    }
  }
}

@include phonel {
  .custom_product_mobile {
    overflow-x: hidden;
    .keen-slider {
      clip-path: inset(-100vw -100vw -100vw 0);
      overflow: unset;
      .keen-slider__slide:last-child {
        margin-right: 20px;
      }
    }
  }
}

.ngx_carousel_wrapper{
  padding-top: 25px;
  margin:0px;
  margin-bottom: 45px;
  @include phonel{
    padding-top: 20px;
    padding-bottom: 30px;
    scrollbar-width: none;
    padding-right: 0;
  }
  @include tabm{
    margin:0px;
  .carousel{
      //display: flex;
      //overflow-x: auto;
      padding-bottom:35px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-right:0px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .slick-track{
    float: left;
    //display: flex;
    //width: 100%;
  }
  @include phonel{
    .slick-list{
      margin-left:-5px;
    }
  }
}
.product_card{
  padding:0;  // padding:0 15px;
  position: relative;
  @media (min-width:768px) and (max-width: 991px){
    padding:0 10px;
  }
  .tag_ui{
    z-index:2;
   &.fav_tag + .local_tag{
     top:37px;
     @include phonel{
       top: 5px;
       left: 32px;
     }
   }
  }
  .hover_link{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    cursor: pointer;
    z-index: 1;

  }
  .product_img_wrapper{
    width:262px;
    height:262px;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    @media (max-width:1300px){
      width:auto;
    }
    @media (max-width:1050px) and (min-width:999px){
      width:auto;
      height:217px;
      img{
        width:100%;
        height:100%;
      }
    }

    @include tabm {
      width: 100%;
      height: auto;
    }
    @include phonel{
      //width:135px;
      //height:135px;
    }
    .shop_now_btn{
      position: absolute;
      margin: auto;
      bottom:20px;
      padding: 0 20px;
      height:45px;
      width: auto;
      transition: all .5s cubic-bezier(.215,.61,.355,1);
      opacity: 0;
      visibility: hidden;
      max-width:140px;
      @include remy(15);
      min-width:140px;
      font-family: 'Helvetica Neue md';
      @include tabm{
        display: none;
      }
    }
  }
  &:hover{
    .shop_now_btn{
      opacity: 1;
      visibility: visible;
      background: var(--gray-200);
    }
  }
  img{
    transition: transform 2s cubic-bezier(.215,.61,.355,1);
  }
  &:hover{
    .product_img_wrapper{
      img{
        transform: scale(1.06);
      }
    }
  }
  @include phonel{
    padding:0px;
    //margin:0 5px;
    &:first-child{
      padding-left:0px;
    }
  }
  .product_desc{
    margin-top: 12px;  //padding-top: 16px;
    display: flex;
    flex-direction: column;
    label.para1{
      font-family: 'Helvetica Neue md';
      margin-bottom: 6px;
      @include remy(15);
    }
    @include tabm{
      display: none;
    }

    .para1{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width:100%;
      @include remy(15);
    }
  }

}
.product_img_wrapper{
  position: relative;
  img{
    height:262px;
    width:262px;
    object-fit:contain;
    aspect-ratio:1/1;


    @include phonel{
      width:100%;
      height:auto;
    }
  }

}
.tag_ui{
  position: absolute;
  background: #000000;
  padding: 5px;
  border-radius: 2.44px;
  color:#fff;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  line-height: 9px;
  left:14px;
  top:13px;
  letter-spacing: 0.05em;
  .icon{
    margin-right:4px;
    display: inline-flex;
    align-items: center;
    @include phonel{
      margin-right: 0;
    }
  }
  @include phonel{
    font-size: 0;
    width:21px;
    height:21px;
    justify-content: center;
    left:6px;
    top:5px;

    &.fav_tag{
        svg{
          width:11px;
          height:9px;
        }
    }
    &.local_tag{
      svg{
        width:9px;
        height:12px;
      }
    }

  }
  &.for_header{
    width:18px;
    height:18px;
  }
}

.count_wrapper{
  min-width: 15px;
  display: inline-flex;
  padding: 4px 3px 4px 3px;
  border-radius: 3px;
  background: #EEE9D4;
  align-items: center;
  justify-content: center;
  max-height:22px;
  .inner_count{
    font-family: 'Helvetica Neue md';
    font-size: 15px;
    font-weight: 500;
    line-height: 11px;
    text-align: center;
  }
}

.btn_wrapper{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 45px;
  padding-bottom: 110px;
  @include tabm{
    padding-bottom:72px;
  }
  .button_primary{
    @include phonel{
      height:45px;
    }
  }
}

.product-carousel_wrapper,.view_product_btn{
  .btn_blk{
    width:100%;
    display: flex;
    justify-content: center;
  }
  .button_primary{
    min-width: 350px;
    margin-bottom: 25px;
    @include phonem{
      min-width: 100%;
    }
  }
  .text_link2{
    color:rgba(#222222 , 0.5);
    max-width: 565px;
    @include phonel{
      @include remy(13);
      text-align: left;
      letter-spacing: -0.1px;
      line-height: 20px;
    }
    a{
      text-decoration: underline;
      color:#222222;
      text-decoration-skip-ink:none;
      text-underline-offset:1px;
      @include trans2s;
      &:hover{
        @include phonel-m {
          color: #222222;
        }
      }
    }
  }
}

.main_wrapper{
  padding:0px 0 0px;
  @include tabm{
    background:var(--white);
    padding:0px 0 0;
  }
  @include phonel{
    padding-top: 0px;
  }
}

.show_more_card{
  //background: rgba(248, 247, 243, 0.80);
  height: 319px;
  padding: 0 15px;
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
  font-family: 'Helvetica Neue md';
  position:relative;
  &.d-none{
    display:none !important;
  }
  @include phonel{
    margin:0 5px;
    padding: 0px;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include tabm{
      aspect-ratio: 1 / 1;
    }
  }
  a{
    height:100%;
    &:hover{
      @include phonel-m {
        span {
          opacity: 0.6;
        }
      }
    }
  }
  @include tabm{
    height:auto;

  }
  span{
    color: var(--primary);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 140% */
    letter-spacing: 0.75px;
    text-decoration-line: underline;
    text-transform: uppercase;
    @include trans2s;
    cursor: pointer;
    position:absolute;
    left:0;
    right:0;
    top:50%;
    transform:translateY(-50%);

  }


}

.product-listing{
  padding-top: 52px;
  @include phonel{
    padding-top:20px;
  }
  .container{
    padding: 0;
    max-width: 100%;
    @include phonel{
      .row {
        --bs-gutter-x: 20px;
      }
    }
  }
  .product_card{
    padding: 0;
    margin-bottom: 45px;
    @include phonel{
      margin-bottom: 25px;
    }
    .product_img_wrapper{
      width: auto;
      @include phonel{
        width:100%;
        height: auto;
        img{
          width:100%;
          height: 100%;
          max-height:165px;
          min-height:165px;
        }
      }
    }
    .product_desc{
      display: flex;
    }
  }
}

.product_header{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  flex-direction:column;
  @include phonel{
    padding-bottom:20px;
  }
  .top_wrapper{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    .left_sec{
      p{
        font-size: 16px;
        font-weight: 400;
        line-height: 33.93px;
        text-align: left;
        color:var(--gray-400);

      }
    }
  }
}


/*co brand scss start here */
.co_brand_module_sec{
  background:var(--floral_white) ;
  padding:122px 0 150px;
  @include tabm{
    padding: 80px 0 80px;
  }
  @include phonel{

  }
}
.co_brand_inner_sec{
  display: flex;
  align-items: center;
  @include tabm{
    flex-direction: column;
  }
  .right_sec{
    @include tabm{
      width:100%;
    }
  }

  @media (min-width: 1550px){
    justify-content:space-between;

    .right_sec{
      padding-right: 56px;
    }
  }
  .left_sec{
    width:51.4%;
    @media (min-width: 1600px){
      width: 54%;
      @media (min-width: 1700px){
        width: 62%;
      }
    }
    @include tabm{
      width:100%;
    }
  }
  .brand_img_sec{
    min-height: 414px;
    margin-left: -150px;
    @media (min-width:1600px){
      margin-left: 0px;
    }
    @include tabm{
      margin-left: 0;
    }
    @include phonel{
      min-height: auto;
    }
    img{
      width:100%;
      max-width: 949px;
      max-height:415px;
      object-fit:cover;
      aspect-ratio:16/9;
      @media (min-width: 1550px){
        max-width: 1026px;
        max-height:559px;
      }

      @include tabl{
        min-height: 414px;
        object-fit: cover;
      }
      @include phonel{
        min-height: auto;
        max-width: 100%;
      }
    }
  }
  .co_brand_desc{
    max-width: 470px;
    margin-left: 85px;
    @media (min-width: 1550px){
      margin-left: 111px;
    }
    @include tabm{
      text-align: center;
      margin: auto;

      >.subtitle1:first-child{
          margin-top:30px;
        }
    }
    @include phonem{
      max-width: 312px;
    }
  }

  .co_brand_img{
    width:150px;
    height:150px;
    margin-bottom: 30px;
    background: #fff;
    border-radius: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    //overflow:hidden;
    @include tabm{
      margin: auto;
      margin-bottom: 30px;
      margin-top: -53px;
      width:100px;
      height:100px;
      display:block;
      img{
        //height:100px;
      }
    }
    a{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width:100%;
      height:100%;
      border-radius: 100%;
      overflow: hidden;
      @include trans4s;
      &:hover{
        @include phonel-m{
          box-shadow:3px 8px 15px rgba(0, 0, 0, 0.05);
        }
      }
    }
    img{
      width:auto;
      max-width:100%;
      max-height:100%;
      &.br-100 {
        border-radius:100%;
      }
      @include tabm{
        max-width:100%;
        max-height:100%;
      }
    }
  }
  .subtitle1{
    margin-bottom: 20px;
    display: inline-block;
    line-height: 28px;
    @include remy(16);
    a{
      display:inline;
      color: var(--primary);
      @include trans2s;
      padding:0 0 0 0px;
      &:hover{
        @include phonel-m {
          opacity: 0.6;
        }
      }
    }
    .powered_by{
      display:inline-flex;
    }
    @media (min-width: 1550px){
      @include remy(16);
      line-height: 28px;
    }
    @include tabm{
      color: var(--primary);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 15px;
      display:inline-block;
      align-items: center;
      justify-content: center;
      flex-wrap:wrap;
      @include phonel{
        line-height:18px;
      }
    }
    @include phonel{
      line-height:38px; margin-bottom:10px;
    }
  }
  .heading_5{
    @include remy(19.2);
    line-height: 32px;
    @media (min-width: 1550px){
      @include remy(19.2);
      line-height: 32px;
    }
    @include tabm{
      color: var(--primary);
      text-align: center;
      @include remy(16);
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
    }
    @include phonel{
      font-size:17.47px
    }
  }
  .text_link1{
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-decoration: underline;
    padding:11px 0;
    color:#006878;
    margin-top:12px;
    display:inline-block;
    font-family: 'Helvetica Neue';
    &:hover{
      color:#1A4B5B;
      opacity:1;
    }
    @include phonel{
      margin-top:24px;
    }
  }
}

.deactive-logo-link{
  cursor: default;
  pointer-events: none;
}
.deactive-logo-link1{
  cursor: default;
  pointer-events: none;
  &:hover{
    opacity: 1!important;
  }
}
/*co brand scss start here */

/*More to explore scss start here */
.more_to_explore{
  padding:110px 0;
  .more_explore {
    .more_products{
      position: relative;
      .arrow_btn{
        @include phonel{
          display:none
        }
        .slick-arrow{
          top:-77px;
        }
      }

    }
    .cont_sec {
      p {
        color: #222222;
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
      }
    }
  }
  &.condition_1{
    .keen-slider__slide{
      min-width:100% !important;
      max-width:10% !important;
    }
    .more_explore{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap:24px;
      .cont_sec{
        p{
          width: 307px;
        }
      }
      .more_products{
        display:flex;
        justify-content: space-between;
        gap:24px;
        flex-direction: column;
        @include phonel{
          width:100%
        }
          .product{
            width: 558px;
            max-width: 100%;
            @include phonel{
              width: 100%;
            }
            @include tabm{
              width:100%
            }
          }
      }
      @include phonel{
        flex-direction: column;
        gap:30px;
        align-items: start;
        .cont_sec{
          p{
            width: 100%;
          }
        }
        .more_products{
          .product{
            width: 100%
          }
        }
      }
    }
  }
  &.condition_2{
    .more_explore{
      display: flex;
      flex-direction: column;
      .cont_sec{
        margin-bottom: 37px;
        @include phonel{
          margin-bottom: 30px;
        }
        p{
          width: 100%
        }
      }
      .more_products{
        @include phonel{
          overflow-X:scroll;
          gap:12.93px;
          margin-right:-20px;
        }
        .product{
          width: 100%;
        }
      }
    }
  }
  &.condition_3{
    .more_explore{
      display: flex;
      flex-direction: column;
      .cont_sec{
        margin-bottom: 37px;
        @include phonel{
          margin-bottom: 30px;
        }
        p{
          width: 100%
        }
      }
      .more_products{
        @include phonel{
          margin-right:-20px;
        }
        .product{
          width: 100%;
        }
      }
    }
  }
  @include phonel {
    padding: 92.5px 0;
    .more_explore {
      .cont_sec {
        h2{
          line-height:42px;
        }
        p{
          font-size: 16px;
          line-height:24px;
        }
      }
    }
  }
}
/*More to explore scss ends here */


/*gallery card start scss*/
.showroom_gallery_card{
  //max-width:368px;
  width:100%;
  overflow: hidden;
  position: relative;
  &:has(p-skeleton){
    max-width:100%;
  }
  &:hover{
    figure img{
      transform:scale(1.06);
    }
    .carousel_image img{
      transform:scale(1.06);
    }
    .p-carousel-indicators, .p-ripple{
      opacity:1;
      visibility:visible;
    }
  }
  @include phonel; @include tabl{
    .p-carousel-indicators {
      opacity: 1 !important;
      visibility: visible !important;
    }
    .p-ripple{
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }
  figure{
    //height:220px;
    aspect-ratio:16/10;
    width:100%;
    //min-width:352px;
    overflow: hidden;
    img{
      width:100%;
      height:100%;
      transition:transform 2s cubic-bezier(.215,.61,.355,1);
    }
  }
  figcaption{
    padding:12px 4px  4px;
    .top_sec{
      display:flex;
      justify-content: space-between;
      align-items:center;
      margin-bottom:6px;
      width:100%;
      .tag{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: right;
        color:#96999F;
        display: inline-flex;
        align-items: center;
        max-width: 50px;
        min-width: 42px;
        justify-content: end;
        cursor:default;
        position: relative;
        z-index: 1;
        .icon{
          display:inline-flex;
          margin-right:6px;
        }
      }
    }
    .sub_title{
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color:#444444;
    }
    .title{
      font-size: 18px;
      font-weight: 500;
      line-height: 24.48px;
      text-align: left;
      color:#444444;
      font-family: 'Helvetica Neue md';
    }
  }
  .card_link{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
  }
}
/*gallery card end scss*/

.p-tooltip {
  .p-tooltip-arrow{
    display:none;
  }
  .p-tooltip-text {
    background: #292929;
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    font-family: 'Helvetica Neue md';
    font-weight: 500;
    line-height: 12px;
    text-align: center;
  }
}


//space card starts here
.space_card{
  margin-bottom: 25px;
  cursor: pointer;
  @include transition(0.2s ease-in-out);
  padding:0;
  @include phonel{
    margin-bottom:25px;
  }
  &:hover{
    background:transparent;
    .space_banner{
      transform: scale(1.06);
    }
  }
  &:active{
    background:transparent;
  }
  figure{
    height:149px;
    position: relative;
    margin-bottom: 0;
    overflow: hidden;
    @media (min-width: 1550px) {
      height:200px;

    }
    @media (max-width: 600px) {
      height: 140px;
      @media (max-width: 480px) {
        height: 94px;
      }
    }

    .space_banner{
      @include transition(all 2s ease);
    }
    .space_banner{
      height:100%;
      width: 100%;
      object-fit: cover;
      @include phonel{
        object-position: center;
      }
    }
  }
}
.space_container{
  //max-height: 625px;
  max-height: 775px;
  //max-height: calc(100vh - 171px);
  overflow-y: scroll;
  overflow-x: hidden;
  //padding-right: 35px;
  //margin-right: -35px;
  &.update_space{
    @include phonel{
      margin-top: 14px;
    }
  }
  @media (max-width: 1550px) {
    max-height: 625px;

  }
  @media (max-width: 1215px) {
    padding-right: 15px;
    margin-right: -15px;

  }
  @include phonel{
    margin-top: 25px;
    max-height: initial;
    overflow-y: initial;
    overflow-x: initial;
    padding-right: 0px;
    margin-right: 0px;
  }
  &::-webkit-scrollbar {
    //width: 12px;
  }

  &::-webkit-scrollbar-track {
    //background: #FCFCFC;
  }

  &::-webkit-scrollbar-thumb {
    //background: #7A7A7A;
    //border-radius: 100px;
    //width: 6px;
    //border: 3px solid #FCFCFC;
  }

  .row{
    @include phonel{
      --bs-gutter-x: 20px;
    }
  }
}

.space_content{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include phonel{
    margin-top: 10px;
  }
  .name_of_space{
    @include remy(16);
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #000000;
    width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space:normal;
  }
}

//space card ends here



// inner page hro banner without image scss  start
.inner_page_banner{
  background:var(--floral_white) ;
  overflow-x: hidden;
  padding:0;
  @include tabm{
    padding-top: 0px;
    background:var(--white);
    padding-bottom: 0;
  }
  .inner_banner_sec{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include tabm{
      flex-direction: column-reverse;
      .left_sec{
        width:100%;
      }
    }

    .hero_desc{
      display: flex;
      justify-content: space-between;
      height:100%;
      @include phonel{
        .link-primary-btn{
          display: none;
        }
      }
      .back-btn{
        max-width: 40px;
        .el-icon{
          width:40px;
          height:40px;
          svg{
            path, circle, g{
              @include trans2s;
            }
          }
        }
        &:hover{
          opacity:1;
          svg{
            path, circle{
              stroke:#222222;
            }
            g{
              opacity:1;
            }
          }
        }
      }
    }




    .link-primary-btn{
      max-width: 90px;
      .el-icon svg{
        max-width:initial;
        @include trans2s;
      }
        &:hover{
          opacity: 0.6;
        }
    }


  }
  &.no_image{
    @include tabm{
      background:var(--floral_white) ;
      //padding:40px 0 90px;
    }
    @include phonel{
      display:none;
    }
    .hero_desc{
      text-align: left;
      min-height: 295px;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding:40px 0 90px;
      @include tabm{
      }
      .heading_1{
        word-wrap:break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .inner_page_banner .inner_banner_sec .link-primary-btn{
      @include tabm {
        position: relative;
        left: 20px;
        top: 90px;
        background: var(--white);
        padding: 4px;
        border-radius: 4px;
        &:hover {
          opacity: 1;
        }

      }
    }
    .right_sec{
      display:none;
    }
  }
}
// inner page hro banner without image scss  End


//navcard starts here

.navbar_card{
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  .navbar_card_link{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:1;
  }
  .navcard_left{
    position: relative;
    width: 85px;
    img{
      width: 85px;
      height: 85px;
      object-fit:contain;
    }

  }
  .navcard_right{
    display: flex;
    padding: 24px;
    background: #F8F7F3;
    width: 100%;
    align-items: center;
    .inner-content{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .inner-text{
        @include remy(16);
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #000000;
      }

    }

  }
}

//navcard ends here

.mobile_title_dropdown{
  display: none;
  @include phonel{
    display: inline-flex;
    width: 100%;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 7;
    padding:10px 20px 10px;
    transition: 0.4s ease-out;
  }
  &.scrolling_top{
    @include phonel{
      top: 54px;
      transition-delay: 0.1s !important;
      transition: 0.3s ease-out;

    }

  }
  &.scrolling_bottom{
    @include phonel{
      top: 0;
      transition: 0.2s ease-out;

    }

  }
  .custom_dropdown_btn{
    font-family: var(--font_Souvenir_light);
    font-size: 28px;
    font-weight: 300;
    line-height: 38px;
    text-align: left;
    padding: 0px;
    border-color:transparent!important;
    position: relative;
    color: #000000;
    white-space: normal;
    display: inline-block;
    word-break: break-word;
    &:after{
      display:none;
    }
    .icon{
      margin-left: 8px;
      display: inline-flex;
      position: relative;
      top: -4px;
    }
  }
}

//brand filter drop-down starts here
.filter_dropdown_sec {
  z-index:4;
  &.userlist_dropdown{
    .dropdown-menu{
      min-width: 296px;
      max-width: 296px;
      .p-listbox-header {
        padding: 0;
        background: #fff;
        border-bottom: 1px solid #E0E1E5;
        .spacer22{
          display: none;
        }
        .p-listbox-filter-icon{
          color: #C7C7C7;
          &.pi-times{
            background: url("../../../assets/images/cross-circle.svg");
            width: 22px;
            height: 22px;
            transform: translateY(-50%);
            background-size: 100%;
            cursor: pointer;
            right: 16px;
            margin-right:0;
            margin-top: 0;
            &:before{
              content: "";
            }
          }
        }
        .p-checkbox {
          display: none;
          &.p-checkbox-disabled{
            +  .p-listbox-filter-container{
            }
          }
        }
        .p-listbox-filter-container{
          input{
            border: none;
            box-shadow: none;
            padding:10px 50px 10px 16px;
            font-style: normal;
            font-weight: 400;
            @include remy(16);
            line-height: 19px;
            height: 50px;
            color:$fc-primary;
            width:100%;
            font-family: 'Helvetica Neue';
            &::-webkit-input-placeholder { /* Edge */
              color:#C7C7C7;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: #C7C7C7;
            }

            &::placeholder {
              color: #C7C7C7;
            }

            &:focus{
              box-shadow: none;
              outline: none;
            }
          }
        }
      }
      .p-listbox {
        border: none;
        box-shadow: none;
        .p-listbox-list {
          padding:4px 0 4px;
          li{
            align-items: flex-start;
            padding: 8px 18px;
            color: #444;
            font-weight: 400;
            font-size: 1rem;
            line-height: 22px;
            display: flex;
            cursor: pointer;
            font-family: 'Helvetica Neue';
            .p-checkbox{
              position: relative;
              top:2px
            }
            &:focus{
              outline: none !important;
              box-shadow: none !important;
            }
            &:hover{
              background: transparent !important;
              .p-checkbox {
                .p-checkbox-box{
                  border-color: #96999f!important;
                  &.p-highlight{
                    background: #000000;
                    border-color: #000000 !important;
                  }
                }
              }
            }
            &.p-highlight{
              background: transparent;
              color:$fc-primary;
            }
          }
          .p-listbox-empty-message{
            height:186px;
            font-style: italic;
            font-weight: 400;
            @include remy(16);
            line-height: 18px!important;
            color: var(--secondary) !important;
            text-align: center;
            position: relative;
            overflow: hidden;
            display: flex;
            justify-content: center;
            font-size: 16px;
            padding-top: 12px!important;
            cursor: initial!important;

          }
          .p-listbox-item {
            .p-checkbox {
              margin-right: .5rem;
            }
          }
        }

        .p-checkbox {
          width:20px;
          height: 20px;
          top: 2px;
          .p-checkbox-box {
            width:20px;
            height: 20px;
            border-radius: 4px;
            border: 2px solid $border-light!important;
            display: flex;
            justify-content: center;
            align-items: center;
            @include trans2s;
            &.p-highlight{
              box-shadow: none;
              outline: none;
              background: #000000;
              border-color: #000000 !important;
            }
          }
        }
      }
    }
  }
  .dropdown-menu{
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.12), 0px 4px 6px -2px rgba(16, 24, 40, 0.07);
    border-radius: 4px;
    padding: 0;
    inset: 0px auto auto 0px!important;
    transform: translate3d(-231px, 32px, 0px) !important;
    max-height: 345px;
    overflow: hidden;
  }
  .quote_member_name{
    word-break: break-word;
  }

  .p-listbox-list-wrapper {
    overflow: auto;
  }
  .p-listbox-filter-container {
    position: relative;
    flex: 1 1 auto;
  }
  .p-listbox-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
    background: url("../../../assets/images/icon_check.svg");
    background-repeat: no-repeat;
    position: relative;
    width: 14px;
    height: 12px;
    background-position: center;
    &:before{
      display: none;
    }
  }
  .pi-search{
    content: "";
    background: url("../../../assets/images/search_icon.svg");
    width:22px;
    height:22px;
    font-size:0;
    right:16px !important;
    margin-top: 0 !important;
    transform: translateY(-50%);
    &:before{
      font-size: 0;
    }
  }
  .dropdown_btn{
    display: flex;
    align-items: center;
    font-style: normal;
    font-family: 'Helvetica Neue md';
    @include remy(16);
    line-height: 28.62px;
    color:#222222;
    @include trans2s;
    position:relative;
    padding: 0;
    border-color: transparent!important;
    &:disabled{
      opacity: 0.3;
    }
    .caret-icon{
      margin-left: 7px;
      display: inline-flex;
      align-items:center;
      @include trans2s;
    }
    svg path{
      @include trans2s;
    }
    @include phonel-m {
      &:hover {
        //color:#DC5A4B;
        opacity: 60%;

        .icon {
          svg {
            path {
              //stroke:#DC5A4B;
              opacity: 60%;
            }
          }
        }

        .caret-icon svg path {
          //stroke:#DC5A4B;
          opacity: 60%;
        }
      }
    }
    .icon{
      display: inline-flex;
      align-items: center;
      margin:0 6px 0 0;
      min-width: 15px;
    }

    &.show{
      .caret-icon{
        svg{
          transform: rotate(180deg);
        }
      }
    }
    &:after{
      display: none;
    }

  }
  .btn_wrapper_dropdown{
    padding: 13px 16px 14px;
    border-top: 1px solid #E0E1E5;
    .btn_primary{
      height:32px;
      border-radius:34px;
      width:100%;
      font-style: normal;
      font-weight: 400;
      @include remy(16);
      line-height: 19px;
      padding:0;
      padding-bottom:3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color:var(--primary);
      border: none;
      color: #ffffff;
      min-width: 125px;
      text-align: center;
      transition: .2s all ease;
      position: relative;
      &:disabled{
        background-color: var(--primary);
        border-color: transparent;
        outline: none;
        box-shadow: none;
        opacity:12%;
      }

    }
  }
  .p-listbox-list-wrapper{
    max-height: 234px;
    overflow-y: auto;
  }
}
//brand filter drop-down ends here

.selected_filter_wrap{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width:100%;
  @include phonel{
    flex-direction: row-reverse;
  }
}
.select_filter_list{
  display: flex;
  width:calc(100% - 95px);
  justify-content: flex-end;
  @include phonel{
    width:calc(100% - 85px);
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: flex-end;
    @include phonel{
      flex-wrap: nowrap;
      overflow-x:overlay;
      margin-right: -12px;
      justify-content: flex-start;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    li{
      cursor: pointer;
      list-style: none;
      border: 1px solid var(--gray-700);
      border-radius: 8px;
      padding:5px 9px 6px 9px;
      font-style: normal;
      font-family: 'Helvetica Neue md';
      line-height: 17px;
      @include remy(14);
      height: 28px;
      display: inline-flex;
      align-items: center;
      margin-left:8px;
      @include trans2s;
      white-space: nowrap;
      margin-bottom: 12px;
      @include phonel{
        margin-bottom:0;
        margin-left:0;
        margin-right:7px;
      }
      &:hover{
        background: #F5F5F5;
        border: 1px solid rgba(199, 199, 199, .25);
      }
    .cross_btn{
      width:16px;
      height:16px;
      display:inline-flex;
      align-items: center;
      justify-content:center;
      margin-left:4px;
    }
    }
  }
}
.cf_btn_wrap {
  .cf_btn {
    background: none;
    border: none;
    font-style: normal;
    @include remy(14);
    font-family: 'Helvetica Neue md';
    line-height: 17.09px;
    letter-spacing: 0.02em;
    color:var(--primary);
    display: inline-flex;
    align-items: center;
    @include trans2s;
    .icon{
      display:inline-flex;
      align-items:center;
      justify-content:center;
      width:16px;
      height:16px;
      margin-left:4px;
    }
  }
}

.empty_message_sec{
  padding: 30px;
  background:var(--floral_white);
  border-radius: 4px;
  text-align: center;
  margin:0;
  figure{
    margin-bottom:22px;
    img{
      @include phonel{
        width:98px;
      }
    }
  }
  .empty_msg_title{
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 22px;
      color:var(--primary);
      margin-bottom: 10px;
      @include phonel{
        font-size: 1.125rem;
        color: #444;
    }
    }
  .empty_desc{
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19px;
    color: #96999f;
    margin-bottom: 10px;
    @include phonel{
      font-size: .9375rem;
    }
  }

.link-primary-btn{
      background: none;
      border: none;
      font-style: normal;
      @include remy(14);
      font-family: 'Helvetica Neue md';
      line-height: 17.09px;
      letter-spacing: 0.02em;
      color:var(--primary);
      display: inline-flex;
      align-items: center;
      @include trans2s;
      .el-text{
        color:var(--primary);
      }
      .el-icon{
        display:inline-flex;
        align-items:center;
        justify-content:center;
        width:16px;
        height:16px;
        margin-left:4px;
      }
    }
}




.product_modal_wrapper {
  margin: 0;
  border-radius: 0;
  height: 100vh;
  transform: none !important;
  overflow:hidden;
  z-index: 99999;
  @include phonel-m{
    display:none;
  }
  .img_contain{
    position:relative;
    width:315px;
    margin:auto;

  }
   .modal-content {
      position: fixed;
      content: "";
      bottom: 0;
      border-radius:8px 8px 0 0;
      height:0;
      overflow:hidden;
      transition:none;
      border:none;
  }
  .modal-body{
    padding:0;
    border-radius:8px 8px 0 0;
    .close{
      width:24px;
      height:24px;
      top:11px;
      right:8px;
      position:absolute;
      z-index:2;
      border:none;
      background:transparent;display: flex;
      align-items: center;
      justify-content: center;
      span{
        display:inline-flex;
        align-items: center;
        justify-content: center;
      }

    }

    .button_primary:focus,
    .button_primary:hover{
      background:var(--gray-200);
    }
  }
  .product_image{
    //height:334px;
    height:355px;
    width:100%;
    position: relative;
    padding:40px 37px 0;
    text-align: center;
    @media (max-width:380px){
      padding:40px 20px 0;
      @media (max-width:350px){
        padding:40px 10px 0;
      }
    }
    .tag_ui.fav_tag, .tag_ui.local_tag, {
      top:18px;
      left:11px;
      font-size: 12px;
      line-height:9px;
      letter-spacing: 0.01em;
      text-align: left;
      width:auto;
      height:auto;
      padding:5px;
      .icon{
        margin-right:3px;
      }
    }
    .tag_ui.local_tag svg{
      width:auto;
      height:auto;
    }
    .tag_ui {
      &.fav_tag{
        + .local_tag{
          top:42px;

        }
      }
    }
    .product_figure{
      width: 315px;
      height: 315px;
      aspect-ratio:1/1;
      object-fit:contain;

    }
    &.last-product{
      .next_prev_btn{
        .next_btn{
          display:none;
        }
      }
    }
    &.first-product{
      .next_prev_btn{
        .prev_btn{
          display:none;
        }
      }
    }
  }
  .product_detail{
    //padding: 20px 20px 33px;
    padding: 12px 0px 33px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: 248px;
    position:relative;
    max-width:315px;
    margin: auto;
      .share_icon{
        position:absolute;
        right:17px;
        top:10px;
      }
    .brand_name{
      @include remy(18);
      font-family: 'Helvetica Neue md';
      line-height: 27px;
      text-align: left;
      margin-bottom:10px;
      color:#000;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow:clip;
      width:100%;
      max-width:303px;
    }
    .product_name{
      @include remy(18);
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      max-width:270px;
      color:#000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .space_list{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin-top: 25px;
      color:var(--secondary);
      //max-height:50px;
      a{
        color:var(--secondary);
        text-decoration:underline;
        text-decoration-skip-ink:none;
      }
    }
    .button_primary{
      border-radius:30px;
      width:100%;
      margin-top:20px;
    }
  }
}
.modal{
  &.show{
    .modal-content {
      height:601px;
      @include trans2s
    }
  }
}
.next_prev_btn{
  z-index: 3;
  img{
    width:auto;
    height:auto;
    object-fit:contain;
  }
  .prev_btn{
      top:162px;
    position:absolute;
    left:4px;
    //box-shadow: 0px 1px 3px 1px #0000001A;
    width:30.55px;
    height:30.55px;
    //border-radius:100%;
    border:none;
    background:transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .next_btn{
    top:162px;
    position:absolute;
    width:30.55px;
    //box-shadow: 0px 1px 3px 1px #0000001A;
    height:30.55px;
    border-radius:100%;
    border:none;
    background:transparent;
    right:4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.no_hero_banner{
  .product-listing{
    @include phonel {
      padding-top: 0;
    }
  }
}


.modal-open::-webkit-scrollbar {
  display: none;
}

.d-block.modal{
  overflow: hidden;
}

.modal-open{
  touch-action:none;
}
html:has(body.modal-open){
  overflow:hidden;
}
.product-carousel_wrapper{
  .space60{
    @include phonel{
      height:40px;
    }
  }

}

.static_animation{
  opacity:0;
  transform:scale(1.04);
  transition:1s ease-in-out;
  &.fadeeffect{
    opacity:1;
    transform:scale(1);
  }
}


.slick-slide {
  &.slide_not_fade {
    opacity: 0;
    transition: 0.6s ease-in-out;

    &:first-child {
      transition-delay: 200ms
    }

    &:nth-child(2) {
      transition-delay: 300ms
    }

    &:nth-child(3) {
      transition-delay: 400ms
    }

    &:nth-child(4) {
      transition-delay: 500ms
    }

    &.slide_fade {
      opacity: 1;
    }
  }
}

.discover_product_wrapper{
  .slide_not_fade{
    opacity:0;
    transition: 0.6s ease-in-out;

    &.slide_fade {
      opacity: 1;
    }
  }

  .col-6{
    &:first-child {
      .slide_not_fade {
        transition-delay: 300ms
      }
    }

    &:nth-child(2) {
      .slide_not_fade {
        transition-delay: 500ms
      }
    }

    &:nth-child(3) {
      .slide_not_fade {
        transition-delay: 700ms
      }
    }

    &:nth-child(4) {
      .slide_not_fade {
        transition-delay: 900ms
      }
    }

    &:nth-child(5) {
      .slide_not_fade {
        transition-delay: 300ms
      }
    }

    &:nth-child(6) {
      .slide_not_fade {
        transition-delay: 500ms
      }
    }

    &:nth-child(7) {
      .slide_not_fade {
        transition-delay: 700ms
      }
    }

    &:nth-child(8) {
      .slide_not_fade {
        transition-delay: 900ms
      }
    }

    &:nth-child(9) {
      .slide_not_fade {
        transition-delay: 300ms
      }
    }

  }
}

.product-listing{
  .slide_not_fade{
    opacity: 0;
    transition: 0.6s ease-in-out;
    &:first-child {
      transition-delay: 300ms
    }

    &:nth-child(2) {
      transition-delay: 500ms
    }

    &:nth-child(3) {
      transition-delay: 700ms
    }

    &:nth-child(4) {
      transition-delay: 900ms
    }

    &.slide_fade {
      opacity: 1;
    }
  }
}

.found_in_filter_list{
  margin-top: 20px;
  display: flex;
  align-items: center;
  .label_text{
    @include  remy(16);
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--secondary);
    margin-right: 12px;
    white-space: nowrap;

  }
  ul{
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    overflow-x:overlay;
    justify-content: flex-start;
    margin-right: -20px;
    padding-right:7px;
    &::-webkit-scrollbar {
      display: none;
    }
    li{
      cursor: pointer;
      list-style: none;
      border: 1px solid var(--gray-100);
      border-radius: 34px;
      padding: 8px 10px;
      font-style: normal;
      line-height: 17px;
      font-weight: 400;
      @include remy(14);
      height: 33px;
      display: inline-flex;
      align-items: center;
      margin-right:7px;
      color: var(--secondary);
      @include trans2s;
      white-space: nowrap;

    }
  }
}
.discover_product_wrapper {
  .slick-list {
    margin: 0 -15px;
    @include phonel{
      margin:0 -5px;
    }
  }
  &.product-carousel_wrapper .slick-arrow{
    top:-98px;
  }
}





.mob_nav_product_wrapper{
  padding-top: 7px;
  .row {
    --bs-gutter-x: 23px;
    justify-content: center;
  }
  .product_inner_wrapper{
    position: relative;
    cursor: pointer;
    width: 100%;
    .product_inner_link{
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      z-index:1;
    }
    .image_container{
      position: relative;
      img{
        width: 100%;
        object-fit: cover;
        @include phonel{
          height: 170px;
          @media screen and (max-width:600px) {
            height: 150px;
            @media screen and (max-width:480px) {
              height: 120px;
              @media screen and (max-width:430px) {
                height: 101px;
              }
            }
          }


        }
      }
    }
    .content-container{
      margin-top: 9px;
      .content_heading{
        @include  remy(16);
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #000000;
      }
      .product_count_wrapper{
       display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        .tag_icons{
          display: inline-flex;
          align-items: center;
          margin-right: 5px;
        }
        .inner_counts{
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 12.66px;
          letter-spacing: -0.05em;
          text-align: left;
          color: #666666;
        }

      }

    }

  }
}


.item_count_wrapper{
  position: absolute;
  right: 8px;
  bottom: 8px;
  padding: 6px;
  border-radius: 14px;
  height: 26px;
  background-color: rgba(34, 34, 34, 0.6);
  gap: 5px;
  display: flex;
  align-items: center;
  @include phonel{
    right: 6px;
    bottom: 6px;
    height: 24px;
  }
  .tag_icons{
    display: inline-flex;
    align-items: center;
    svg{
      @include phonel{
        width: 11px;
        height: 11px;
      }
    }
  }

  .inner_counts{
    @include remy(15);
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #FCFCFC;
    display: inline-flex;
    align-items: center;
    //margin-bottom: 2px;
    @include phonel{
      @include remy(14);
    }

  }
}


[data-aos^=fade][data-aos^=fade]{
  opacity:0;
}
.custom_nav_dropdown{
  &.show{
    [data-aos^=fade][data-aos^=fade].aos-animate{
        opacity:1;
    }
  }
}

.product_carousel_container{
  .ngx_carousel_wrapper{
    .slick-list{
      margin:0 -15px;
      @media (min-width:768px) and (max-width:991px){
          margin:0 -10px;
      }
      @media (max-width:767px) {
        //margin:0 -5px
        margin: 0 -20px;
        padding: 0 20px 0 15px;
      }
    }
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInEffect {
  animation: fadeInAnimation ease-in-out 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.p-skeleton{
  background-color:#F5F5F5;
  border-radius:0;
}


.custom_nav_dropdown{
  .header_skelton {
    .p-skeleton {
      width: 100% !important;
      height: 179px !important;
    }
  }
}



//home page carousal css

.app_title{
  //max-width:calc(100% - 150px);
  @include phonel{
    //max-width:calc(100% - 140px);
  }

}
.carousel_header{
  display: flex;
  justify-content: space-between;
  .left_sec{
    display:inline-flex;
    align-items:center;
    width:calc(100% - 200px);
    @include phonel{
      align-items:flex-start;
    }
    @include tabm{
      justify-content: space-between;
      width:100%;
      gap: 15px
    }
  }
  .right_sec{
    display: flex;
    position: relative;
    @media (max-width:999px){
      display:none;
    }
    .slick-arrow{
      top:4px !important;
      &.slick-prev{
        right:61px;
      }
      &.slick-next{
        right:0;
      }
    }
  }
  .heading_4{
    //margin-right: 15px;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    overflow: hidden;
    text-align:left;
    //max-width:calc(100% - 150px);
    max-width: 100%;
    @include tab-m{
      margin-right:0;
    }
    @include phonel{
      //max-width:calc(100% - 140px);
      @include remy(18);
      line-height: 24px;
      width:100%;
      white-space: normal;
      overflow: hidden;
      //text-overflow: ellipsis;
      //display: -webkit-box;
      //-webkit-line-clamp:2;
      //-webkit-box-orient: vertical;
    }

    .count_mobile{
      @include phonel{
        .inner_count{font-size:15px !important;}
      }
      display:inline-flex;
      background:none;
      padding:0 3px;
      margin-left: 8px;
      background:transparent;
      @include tab-m{
        padding: 0 0;
        margin-left: 19px;
        background: transparent;
        margin-right: 19px;
      }
      @include tabm{
        /*display:inline-flex;*/
        display:inline;
        .inner_count{
          font-size: 15px;
          font-weight: 400;
          line-height: 12.66px;
          letter-spacing: -0.75px;
          text-align: left;
          color:var(--gray-400);
          display:inline-flex;

        }
      }
      .icon{
        margin-right:3px;
        top: -2px;
        position: relative;
      }


      .inner_count{
        font-size:14px;
        font-weight:400;
        line-height:21px;
        letter-spacing:-0.75px;
        color:var(--gray-400);
        font-family: 'Helvetica Neue';
        @media (min-width:992px) {
          position: relative;
          top: -1px;
        }
      }
    }
  }
}
.view_all_btn{
  @include phonel{
    min-width: 81px;
    img{height:10px}
  }
  &.mob_btn{
    display:none;
    @include tabm{
      display:flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.dskp_btn{
    position: relative;
    top: 3px;
    @media (min-width:768px){
      min-width:75px;
    }
    @include tabm{
      top:0;
      display:inline-flex;
      align-items: center;
      .count_wrapper{
        display:none;

      }
    }
  }
  .text_link1{
    color:var(--primary);
    font-size: 14px;
    font-weight: 500;
    line-height:21px;
    letter-spacing: 1.2px;
    text-align: center;
    text-transform: uppercase;
    @include phonel{
      font-size:12px
    }

    &:focus, &:hover{
      text-decoration:underline;
    }
  }
  .count_wrapper{
    display: inline-flex;
    margin-left: 8px;
    background:transparent;
    .icon{
      margin-right:3px;
    }
    .inner_count{
      font-size:14px;
      font-weight:400;
      line-height:21px;
      letter-spacing:-0.75px;
      color:var(--gray-400);
      font-family: 'Helvetica Neue';

    }
  }
  .mobile_arrow {
    //width: 20px;
    width:auto;
    height: 20px;
    display:none;
    align-items: center;
    justify-content: flex-end;
    margin-left:10px;
    @include tabm{
      display: inline-flex;
    }
  }
}


//home page carousal css ends here


.mob_nav_top{
  padding-top: 10px;
}
.product_content_wrapper{
  cursor: pointer;
  padding: 14px 0px;
  border-bottom: 1px solid #DADADA;
  .product_content_inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content-text{
      @include remy(22);
      font-weight: 400;
      line-height: 42px;
      text-align: left;
      color: #222222;
    }
    .price_tag_wrapper{
      display: flex;
      align-items: center;
      .arrow_icon{
        display: inline-flex;
        align-items: center;
        margin-left: 11px;
        opacity: 1!important;
        visibility: visible!important;
        svg{
          width: 17px;
          path{
            fill: #222222!important;
          }
        }
      }
    }

  }
}


.top_count_wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  .tag_icons{
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
  }
  .inner_counts{
    display: inline-flex;
    align-items: center;
    text-align: left;
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    line-height: 15.19px;
    letter-spacing: -0.02em;
    @include phonel{
      letter-spacing: 0em;
    }

  }

}

.browse_space_heading{
  padding-top: 14px;
  @include remy(22);
  font-weight: 400;
  line-height: 42px;
  text-align: left;
  &.no_img_space{
    color: rgba(34, 34, 34, 0.5);
    padding-top: 30px;
  }
}

.showroom_card_slick_wrapper{
  position:relative;
  .carousel_image{
    aspect-ratio:16/10;
    overflow: hidden;
    img{
      aspect-ratio:16/10;
      transition:transform 2s cubic-bezier(.215,.61,.355,1);
    }
  }
  .p-carousel-indicators{
    position: absolute;
    bottom:12px;
    left:0;
    right:0;
    margin:auto;
    display:flex;
    align-items: center;
    justify-content: center;
    gap:8px;
    opacity:0;
    visibility: hidden;
    z-index:1;
    @include trans2s;
    li{
      list-style: none;
      &.p-highlight{
        button{
          background:#fff;
        }
      }
      button{
        background:rgba(224, 225, 229, 0.5);
        width:8px;
        height:8px;
        border-radius:100%;
        display:flex;
        font-size:0;
        border:none;

      }
    }
  }
  .p-ripple{
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    z-index: 1;
    width:40px;
    height:40px;
    border:none;
    display:flex !important;
    align-items:center;
    justify-content:center;
    padding:0;
    gap: 6px;
    border-radius:100%;
    background:rgba(255,255,255,0.6);
    opacity: 0;
    visibility: hidden;
    @include trans2s;
    span{
      display:inline-flex;
      align-items: center;
    }
    &.p-disabled{
      display:none !important;
    }
    svg path, svg{
      @include trans2s;
    }
    &:hover{
      svg{
        transform: scale(1.2);
      }
      background:$white;
      svg path{
        stroke:$orange;
      }
    }

    &.p-carousel-next{
      right:8px;
    }
    &.p-carousel-prev{
      left:8px;
    }
  }
}
