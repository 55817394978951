/* Color Pallet */
$fc-primary: #222222;
$fc-secondary:#96999F;

/* white shades*/

$white:#ffffff;
$white-40:rgba(#FFFFFF , 0.4);
$white-50:rgba(#FFFFFF , 0.5);
$white-60:rgba(#FFFFFF , 0.6);
$white-70:rgba(#FFFFFF , 0.7);
$white-90:rgba(#FFFFFF , 0.9);

$floral_white:#F8F7F3;


/* gray shades*/
$gray-100:#E0E1E5;
$gray-200:#222222;
$gray-300:#333333;
$gray-400:#666666;
$gray-500:#F7F7F7;
$gray-600:#F5F5F5;
$gray-700:#C7C7C7;
$gray-80:rgba(#222222 , 0.8);
$gray-90:rgba(#222222 , 0.9);


/* teal shades*/
$teal:#006878;
$teal-900:#1A4B5B;
$teal-200:#5592A0;

/*orange shades*/
$orange:#DC5A4B;
$orange-hover:#E37B6F;

/* yellow shades*/
$yellow:#FAC55E;

:root {
  --items-per-page: 9;
  --primary: #{$fc-primary};
  --secondary: #{$fc-secondary};
  --teal: #{$teal};
  --teal-900: #{$teal-900};
  --teal-200: #{$teal-200};
  --orange: #{$orange};
  --orange-hover: #{$orange-hover};
  --yellow: #{$yellow};
  --gray-100: #{$gray-100};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-80: #{$gray-80};
  --gray-90: #{$gray-90};

  --floral_white:#{$floral_white};
  --white: #{$white};
  --white-40: #{$white-40};
  --white-50: #{$white-50};
  --white-60: #{$white-60};
  --white-70: #{$white-70};
  --white-90: #{$white-90};
}
.gray_bg{
  background:var(--gray-500);
}


/* color themes on whole page */

/* Background Colors */
$bg_white: #FFFFFF;
$bg_1: #DC5F50;
$bg_2: #F8F4F1;
$bg_3: #E5E3D6;
$bg_4: #859485;
$bg_5: #D7D0CA;
$bg_6: #466956;
$bg_7: #EC684B;

/* Font Colors */
$f_white: #FFFFFF;
$fc_1: #242424;
$fc_2: #466956;
$fc_3: #859485;
$fc_4: #DC5F50;
$fc_5: #444444;
$fc_6: #EB674E;
$fc_white: #FFFFFF;
$fc-hint: #C7C7C7;
$fc-red: #EA1212;
$fc-green: #2DCE3A;

//Orange
$orange-shade: #C65144;
$orange-tint: #E37B6F;
$orange-400:#EB674E;
$orange-300:#EA8069;
$orange-light:#F8DEDB;
$orange-light1:#EECBC7;
/* color themes on whole page */

//Misc. Colors
$border-light: #E0E1E5;
$border-dark: #96999F;
$teal-shade-2:#376878;
