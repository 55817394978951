.button_primary{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-200);
  border: none;
  border-radius:45px;
  @include remy(18);
  line-height: 28px;
  color:var(--white) !important;
  padding: 0 30px 0 30px;
  height:60px;
  min-width: 125px;
  text-align: center;
  @include trans2s;
  -webkit-appearance: none;
  appearance: none;
  &.md{
    height:46px;
    border-radius: 4px;
    @include remy(16);
  }
  &.sm{
    height:45px;
    border-radius: 4px;
    @include remy(18);
    font-weight: 400;
    line-height: 28px;
    text-align: center;
  }
  &:visited {
    color:#ffffff !important;
  }
  &:hover{
    color: var(--white) !important;
    @include phonel-m {
      background-color: var(--gray-80);
      color: var(--white);
    }
  }
  &:focus{
    color: var(--white) !important;
    @include phonel-m {
      background-color: var(--gray-90);
      border-color: transparent;
      outline: none;
      box-shadow: none;
      color: var(--white);
      &:active {
        background-color: var(--gray-80);
        border-color: transparent;
        outline: none;
        box-shadow: none;
        color: var(--white);
      }
    }
  }

  &:active{
    color: var(--white) !important;
    @include phonel-m {
      background-color: var(--gray-80);
      border-color: transparent;
      outline: none;
      box-shadow: none;
      color: var(--white);
      transform: scale(0.98);
    }
  }

  .icon{
    display:inline-flex;
    margin-right:6px;
  }
}
.button_secondary{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color:transparent;
  border-radius: 4.4px;
  font-family: 'Helvetica Neue md';
  @include remy(18);
  line-height: 24px;
  letter-spacing: 0.01em;
  padding: 0 30px 0 30px;
  height:56px;
  min-width: 125px;
  text-align: center;
  @include trans2s;
  -webkit-appearance: none;
  border: 2px solid var(--teal);
  color: var(--teal);
  &.md{
    height:46px;
    border-radius: 4px;
  }
  &.white{
    background: rgba(0, 0, 0, 0.10);
    border: 2px solid var(--white);
    color:var(--white);
    &:hover{
      background-color: transparent;
      color: var(--white);
      border-color:var(--white);
      opacity: 0.6;
    }
    &:focus{
      background-color:transparent;
      border-color:var(--white);
      color:var(--white);
      &:active{
        background-color:transparent;
        color:var(--white);
      }
    }
    &:active{
      background-color:transparent !important;
      color:var(--white);
    }
  }
  &:hover{
    background-color: var(--white);
    color: var(--teal-200);
    border-color:var(--teal-200);
  }
  &:focus{
    background-color: var(--white);
    color: var(--teal-200);
    border-color:var(--teal-200);
    outline: none;
    box-shadow: none;
    &:active{
      background-color: var(--white);
      color: var(--teal-200);
      border-color:var(--teal-200);
      outline: none;
      box-shadow: none;
    }
  }

  &:active{
    background-color:var(--white)!important;
    color:var(--teal-200)!important;
    border-color:var(--teal-200)!important;
    outline: none;
    box-shadow: none;
    transform:scale(0.98);

  }
}
.button_secondary_white{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--white);
  background: rgba(0, 0, 0, 0.15);
  color: var(--white);
  border-radius: 4.4px;
  font-family: 'Helvetica Neue md';
  @include remy(18);
  line-height: 24px;
  letter-spacing: 0.01em;
  padding: 0 30px 0 30px;
  height:56px;
  min-width: 125px;
  text-align: center;
  @include trans2s;
  -webkit-appearance: none;
  &.md{
    height:46px;
    border-radius: 4px;
    @include remy(16);
    line-height:normal;
  }
  &:hover{
    background-color: var(--teal);
    color: var(--white);
    border-color:var(--teal);
  }
  &:focus{
    background-color:var(--teal);
    color:var(--white);
    border-color:var(--teal);
    outline: none;
    box-shadow: none;
    &:active{
      background-color:var(--teal);
      color:var(--white);
      border-color:var(--teal);
      outline: none;
      box-shadow: none;
    }
  }

  &:active{
    background-color:var(--teal-900)!important;
    color:var(--white)!important;
    border-color:var(--teal-900)!important;
    outline: none;
    box-shadow: none;
    transform:scale(0.98);

  }
}

.text_primary{
  color: var(--orange);
  @include remy(18);
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  text-decoration-line: underline;
  background:transparent;
  border: none;
  height:38px;
  display:inline-flex;
  align-items: center;
  @include trans2s;
  -webkit-appearance: none;
  &:hover{
    color: var(--orange-hover);
  }
}




//join us button


.link-primary-btn{
  display: inline-flex;
  align-items: center;
  transition: .2s all ease;
  border-color: transparent;
  background: transparent;
  .el-icon{
    display: inline-flex;
    vertical-align: middle;
    margin-right: 4px;
    width: 21px;
    height: 21px;
    align-items: center;
    svg{
      max-width: 18px;
    }
  }
  .el-text{
    display: inline-flex;
    vertical-align: middle;
    font-style: normal;
    font-family: 'Helvetica Neue md';
    @include remy(15);
    line-height: 21px;
    letter-spacing: 0.05em;
    color: var(--gray-400);
  }
  &:hover{
    .el-text{
      opacity: 60%;
      @include trans2s
    }
    .inner-svg{
      opacity: 60%;
      @include trans2s
    }
  }
  &:focus{
    outline: none;
    box-shadow: none;
    .el-text{
      //color: #C65144;
    }
    .inner-svg{
      //fill: #C65144;
    }
  }

}



